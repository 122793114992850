import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from './pages/Landing';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';
import Resume from './pages/Resume';
import VidEdits from "./pages/VideoEditingPortfolio";
import Graphics from "./pages/GraphicDesignPortfolio";
import TwitchmoteCaseStudy from "./pages/CaseStudies/TwitchmoteCS";
import NightOwlCaseStudy from "./pages/CaseStudies/NightOwlCS";
import BobaCaseStudy from "./pages/CaseStudies/BobaShopLockScreenCS";
import DuoCaseStudy from "./pages/CaseStudies/DuoFinderCS";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element= <Landing/> />
        <Route path="/portfolio" element= <Portfolio/> />
        <Route path="/resume" element= <Resume/> />
        <Route path="/contact" element= <Contact/> />
        <Route path="/portfolio/videoediting" element= <VidEdits/> />
        <Route path="/portfolio/graphics" element= <Graphics/> />
        <Route path="/portfolio/twitchmote" element= <TwitchmoteCaseStudy/> />
        <Route path="/portfolio/nightowl" element= <NightOwlCaseStudy/> />
        <Route path="/portfolio/bobashop" element=<BobaCaseStudy/> />
        <Route path="/portfolio/duoq" element=<DuoCaseStudy/> />



      </Routes>
    </BrowserRouter>

  )
}

export default App;

