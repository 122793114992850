import Navbar from "../components/Navbar"
import styled from "styled-components"
import Title from "../components/Title"
import Project from "../components/Project";
import * as projectData from '../projects.json';
import { Player } from '@lottiefiles/react-lottie-player';
import Carousel from "../components/Carousel";


export default function Portfolio() {

   const data = Object.entries(projectData)
   let arr = data.pop()
   arr = arr.pop()

   

   return(
    <>
    <Wrapper >
    <Player style={{ position: "absolute", top:0, left: 0, padding: 0, margin: 0, zIndex: 0, width: "100vw", maxHeight: "100vh" }} src='/assets/bg.json' loop autoplay/>

    <Navbar bgCol="rgba(0, 0, 0, 0)"></Navbar>
    <Title txt="PORTFOLIO"/> 
    <LinkWrapper>
      <Lin href="/portfolio"> Web Dev</Lin> |
      <Lin href="/portfolio/videoediting"> Video Editing </Lin> |
      <Lin href="/portfolio/graphics"> Graphic Design </Lin>

    </LinkWrapper>
    <Carousel items={arr} />


  
      </Wrapper>
    </>
   )
  

}

const Wrapper = styled.div`
   justify-content: center;
   align-items: center;
   height: 100vh;
   width: 100vw;
   z-index: -1;
   background-color: #FFDFDF;
   overflow: hidden;
   @media all and (max-width: 1020px){
    overflow-y: auto;
}
`
const LinkWrapper = styled.div`
  display: flex;
  font-size: 1.1em;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
    position: relative;
 

`
const Lin = styled.a`
  text-decoration: none;
  color: #3E3D3D;
  padding: 0.5%;
  text-align: center;
  transition: 0.3s;
  &:hover{
    color: #FFB5B5;
    transform: scale(1.1);
  }
  @media all and (max-width: 1020px){
    font-size: 0.7em;
  }
`

