import styled from 'styled-components';
import Links from './Links';

export default function Navbar(props){
    return(
        <>
        <Wrapper bgCol={props.bgCol}>
            <Icon imgSrc="/assets/icons/home.png" href='/'/>
            <div style={{marginRight: "2%"}}>
                <Links ></Links>
            </div>
        </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
    z-index: 1;
    position: relative;
    display: flex;
    font-size: 2.5em;
    left: 0;
    top:0;
    align-items: center;
    justify-content: space-between;
    padding: 1%;
    background-color: ${(props => props.bgCol)};
    overflow: hidden;
    @media all and (max-width: 700px){
        font-size: 1.6em;
        padding: 4%;
    }
    
`

const Icon = styled.a`
  content: url(${(props => props.imgSrc)});
  max-width: 20px;
  max-height: 20px;
  transition: 0.3s;
  z-index: 1;
  position: relative;
  &:hover{
    transform: scale(1.3);

  }
`

