import Navbar from "../components/Navbar"
import styled from "styled-components"
import Title from "../components/Title"

export default function GraphicDesignPortfolio() {

   return(
    <>
    <Navbar bgCol="#FFDFDF"></Navbar>
    <Title txt="PORTFOLIO"/> 
    <LinkWrapper>
      <Lin href="/portfolio"> Web Dev</Lin> |
      <Lin href="/portfolio/videoediting"> Video Editing </Lin> |
      <Lin href="/portfolio/graphics"> Graphic Design </Lin> 

    </LinkWrapper>
    <Wrapper >
      <PhotoWrapper>
      <StyledImg url="/assets/graphics/1.jpg"></StyledImg>
      <StyledImg url="/assets/graphics/2.png"></StyledImg>
      <StyledImg url="/assets/graphics/3.png"></StyledImg>
      <StyledImg url="/assets/graphics/4.jpg"></StyledImg>
      <StyledImg url="/assets/graphics/6.png"></StyledImg>
      <StyledImg url="/assets/graphics/9.jpg"></StyledImg>
      <StyledImg url="/assets/graphics/10.png"></StyledImg>
      <StyledImg url="/assets/graphics/8.jpg"></StyledImg>
      </PhotoWrapper>
    </Wrapper>
    </>
   )
  

}

const Wrapper = styled.div`
   justify-content: center;
   align-items: center;
   display: flex;
   flex-wrap: wrap;
   padding-top: 2%;
`

const PhotoWrapper = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    max-width: 85vw;
    height: 315px;
    @media all and (max-width: 600px){
        width: 90%;
        aspect-ratio: 16/9;
        height: 100%;
    }
`

const LinkWrapper = styled.div`
  display: flex;
  font-size: 1.1em;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
    position: relative;
 

`
const StyledImg = styled.img`
  content: url(${(props) => props.url});
  padding: 1%;
  height: 30vh;
  @media all and (max-width: 1020px){
    height: 80%;
}
`
const Lin = styled.a`
  text-decoration: none;
  color: #3E3D3D;
  padding: 0.5%;
  text-align: center;
  transition: 0.3s;
  &:hover{
    color: #FFB5B5;
    transform: scale(1.1);
  }
`