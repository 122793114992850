import styled from "styled-components"
import Navbar from "../../components/Navbar"
import Title from "../../components/Title"
import * as InitialSketches from './bobaInitialSketches.json';
import * as DetailedSketches from './bobaDetailedSketches.json';
import { Player } from '@lottiefiles/react-lottie-player';
import PhotoGrid from "../../components/PhotoGrid";

export default function BobaCaseStudy(){
   
    const data = Object.entries(InitialSketches)
    let arr = data.pop()
    arr = arr.pop()

    const data2 = Object.entries(DetailedSketches)
    let arr2 = data2.pop()
    arr2 = arr2.pop()
   
    return(
    <div className="scrollable">
        <Wrapper>
            <Navbar bgCol="rgba(0, 0, 0, 0)"></Navbar>
            <Title txt= "BOBA SHOP LOCK SCREEN CASE STUDY"/> 
            <Content>
            <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  00 Project Description & Demo Video ───────
                </Heading>
                <IFrameWrapper>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/uFnIiZ5gp64" title="YouTube video player" frameborder="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </IFrameWrapper>
                <TextBlock>
                    Boba Shop Lock Screen is a cool and unique way to keep others from using your phone! For this lockscreen, your password is your regular bubble tea order- customized to your exact standards.  First, pick your drink of choice and mix it until the desired result is achieved.  Shake your drink (via shaking your phone) to mix it until it's just right- but be sure not to under or overmix or your order will be all wrong!  When you are done mixing and ready to unlock, stab the straw into your drink (via swinging your phone in a swift downwards motion).  If your drink is correct, you will be permitted to enter.                </TextBlock>
                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  01 Background & Context ───────
                </Heading>
                <TextBlock>
                    This was one of the many group projects I was a part of during my 2nd human-computer interaction course. We were tasked to make a unique way to unlock your phone. It didn't necessary have to be practical, but it had to be unique. This project had an emphasis in unique ideas so we had the opportunity of creating a mock lock screen using a website instead of having to mess around with phone-related things we were not familiar with. This gave us a lot of freedom to come up ideas that we might not have thought of had we been constrained to learn how to implement the project properly. I worked in a group with 3 others. The group members were: Seyon, Jane, Mackenzie and me (Mar)
                </TextBlock>
                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  02 Initial Design Process ───────
                </Heading>
                <TextBlock>
                    We wanted to make use of the phone's accelerometer data in our lockscreen so that users had to move their phone a certain way in order to unlock the phone. Keeping that aspect in mind, each group member came up with 10 initial sketches (meaning 30 sketches/ideas in total!). Each sketch would be very simple and would represent a different idea. While coming up with ideas we tried to keep ourselves free of judgement so that we could come up with as many outside-of-the-box ideas as possible. We later met up and discussed our sketches as a group. We narrowed it down to the following sketches. Each sketch has the name of the person that came up with it next to it alongside a description of the sketch. I came up with the last 2 sketches!
                </TextBlock>

                <PhotoGrid items={arr}/>
                
                <TextBlock>
                    After narrowing our ideas down to 10 sketches, we decided to discuss a little more. We wanted something that we all felt was fun yet unique and innovative enough to be used for this project. We all agreed to focus more on a recipe-based lockscreen. Like the smoothie idea from Seyeon! 
                </TextBlock>
             
                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  03 Refining Our Idea ───────
                </Heading>
                <TextBlock>
                    Now that we had one main idea we wanted to focus on, we proceeded to make different and more detailed variations of it. We each came up with 10 refined sketches. Our refined sketches reflect the different ideas of how we could use gestures to create different food and drinks. The following sketches are the 10 we liked the most. 
                </TextBlock>
                <PhotoGrid items={arr2}/>
                <TextBlock>
                    In the end we settled on a Bubble Tea Shop because we all love bubble tea and the gestures were interesting and easier to implement in the given time. At first we considered choosing different ingredients and having several steps like pouring the milk and adding the toppings. However we realized that this was a bit too much to implement and seemed a bit tedious for a lock screen so we chose a more simplified version. Thus, we came up with our final design of creating a customized bubble tea order by choosing a bubble tea flavor, shaking the phone to mix it and swinging your phone downward to put in the straw.
                </TextBlock>
               
                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  04 Work Distribution & Initial Designs ───────
                </Heading>
                
                <TextBlock>
                    Since we were lucky enough to have 2 amazing artists on out team, they took care of all the art necessary for this project. I worked on designing and implementing the actual product with a little bit of animation mixed in there as well! I had 1 other person helping me with the code. (the other 2 were implementing another project that was also for this class) 
                </TextBlock>

                <TextBlock>
                    I quickly made some mock-ups using Adobe XD. The following atrocities are what I came up with: 
                </TextBlock>
                <PhotoBlock>
                    <Photo imgSrc="/assets/projimgs/bobashop/old1.png"></Photo>
                    <Photo imgSrc="/assets/projimgs/bobashop/old2.png"></Photo>
                </PhotoBlock>

                <TextBlock>
                    Once I finished implementing the basic functionality of the mock lock screen, I felt really unhappy with the way it looked. Thankfully, we still had time.
                </TextBlock>

                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  05 Redesign ───────
                </Heading>

                <TextBlock>
                    After getting feedback from my teammates and brainstorming some ideas for the UI with them, I decided to try to redesign the UI once more. Thanks to the magic of Photoshop and my teammate's wonderful art,  I was able to create a more aesthetically pleasing UI. This is what the final UI mock up looked like:
                </TextBlock>
                <Photo imgSrc="/assets/projimgs/bobashop/old3.png"></Photo>
                <TextBlock>
                    I used Photoshop to create the squiggly background and the pink blobs behind the drinks. I also used the "Select Flavour" title that one of my talented teammates drew. Finally, I decided to add some flair to the UI, and more importantly a way to give the user feedback when they move their phone. This was done by utilizing animations. I had a shaking animation that would play while the user shook their phone and a straw-stabbing animation that played when the user swung their phone down. These simple lottie animations were created using Adobe After Effects. Sadly, we will soon discover that the animations, while cool were not a good way to reach this goal.
                </TextBlock>
                <PhotoBlock>

                <Player style={{ width: "80%"}} src='/assets/projimgs/bobashop/berryShake.json' loop autoplay/>
                <Player style={{ width: "50%"}} src='/assets/projimgs/bobashop/berryStraw.json' loop autoplay/>

                </PhotoBlock>


                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  06 Final implementation & Tweaks ───────
                </Heading>
                <TextBlock>
                    This is what the final design ended up looking like. Screens are in chronological order of what the user journey might look like.
                </TextBlock>
                <PhotoBlock>
                    <Photo imgSrc="/assets/projimgs/bobashop/finalScreens/1.jpg"></Photo>
                    <Photo imgSrc="/assets/projimgs/bobashop/finalScreens/2.jpg"></Photo>
                    <Photo imgSrc="/assets/projimgs/bobashop/finalScreens/3.jpg"></Photo>
                    <Photo imgSrc="/assets/projimgs/bobashop/finalScreens/4.jpg"></Photo>
                    <Photo imgSrc="/assets/projimgs/bobashop/finalScreens/5.jpg"></Photo>
                    <Photo imgSrc="/assets/projimgs/bobashop/finalScreens/6.jpg"></Photo>
                    <Photo imgSrc="/assets/projimgs/bobashop/finalScreens/7.jpg"></Photo>

                </PhotoBlock>

                <TextBlock> 
                    <a href="https://kiwigi.github.io/581RecipeLockscreen/">
                        Website link
                    </a>
                </TextBlock>

                <TextBlock>
                    While testing the app, I also realized that users couldn't always see the animations while they were moving their phone around. Eliminating ther primary use. Because of this, we decided to add sound effects to accompany the animations! So now, when a user shakes their device it makes a shaking sound to let them know that the shaking is working! Ideally the sound effect would change depending on what mixture level you're currently at (kind of how the sound that filling up your water bottle makes changes as it gets fuller). We didn't have time to implement something like this so we stuck with the single sound effect for now.
                </TextBlock>

                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  07 Retrospective  ───────
                </Heading>

                <TextBlock>
                    I had a lot of fun working on this project and I'm grateful that I was able to work on it with such talented and creative people. This project was completed over 6 months before I'm writing this. Looking back, I feel as though back then I didn't think enough about equity and accesibility. I will list some of the main flaws that I presently see with my design:
                </TextBlock>
                <TextBlock>
                    <li>
                    Overall low colour contrast ratio: I mostly used white and pastels. This makes it very difficult for people with visual impairments to see what's going on. 
                    </li>
                    <li>
                    Lack of flavour text labels: some users might have a hard time telling 2 drinks apart. They might look like the same colour to them due to color blindness or other factors. Additionally, some users might not have seen the flavours before and might not know what they look like. Either way, adding text labels to each flavour just makes a lot more sense.
                    </li>
                    <li>
                    Undescriptive instructions: For the "put in the straw" screen, the text isn't very clear on what kind of motion you're supposed to make. Most people might not understand!        
                    </li>  
                    <li>
                    Additional auditory queues are needed: Like I sad before, changing the sound as the mixture level fills up is ideal. Additionally, there should also be a sound for when the straw has gone in the drink.
                    </li>
                    <li>
                    Heptic feedback option: Another option is to give heptic feedback while the user shakes and inserts their straw. This would be a great option for people that have trouble hearing.
                    </li>
                    <li>
                    Automatically asking iOS users for permissions instead of making them press a button: I can see how many users might not see the button and will instead become frustrated because the application will not work on iPhone until they enable permissions. A way to automatically ask them for permissions right away would make it easier for the user.
                    </li>
                    <li>
                    The font: more of a visual problem I see here. I really dislike the font I ended up using. I feel as though it would have been a lot better if I used a font similar to the 'Select Favour' font on the start screen. It would make no sense to the user why the title font has suddenly changed from one screen or another. The font I chose is also not super pleasant aesthetically and it is also not always easy to read.
                    </li>
                    <li>
                    Better mix level meter for iPhone: For some reason the way the meter looked on iPhone was different than what it looked like on Android. The iPhone version was a lot harder to see and it was super thin.
                    </li>
                </TextBlock>

                <TextBlock>
                    I could definelty keep going, but I feel as though those are the main issues I find with the design currently. If you find some more issues with the design feel free to contact me! I'm always open to constructive criticism and discussion.
                </TextBlock>

            
            </Content>
        </Wrapper>

    </div>

    )
}

const Wrapper = styled.div`
    justify-content: center;
    align-items: center;
    width: 100vw;
    z-index: -1;
    background-color: #FFDFDF;
    overflow-x: hidden;
`

const Content = styled.div`
    background: white;
    margin-left: auto;
    margin-right: auto;
    border-radius: 34px;
    margin-top: 50px;
    padding: 3%;
    height: 100%;
    width: 80vw;
    justify-content: center;

`
const SnowFlake = styled.img`
    content: url(${(props) => props.imgSrc});
    height: 40px;
    width: 40px;
    @media all and (max-width: 1020px){
    height: 20px;
    width: 20px;
}

`
const Photo = styled.img`
    /* display: none; */
    content: url(${(props) => props.imgSrc});
    border-radius: 34px;
    max-width: 500px;
    max-height: 500px;
    object-fit: cover;
    padding: 2%;
    @media all and (max-width: 1020px){
    max-width: 80vw;
    }

`
const IntroImg = styled.img`
   content: url(${(props) => props.imgSrc});
    max-width: 100%;
    object-fit: cover;
    aspect-ratio: 16/9;
    border-radius: 34px; 
`

const Heading = styled.h1`
    font-size: 30px;
    text-align: left;
    display: flex;
    @media all and (max-width: 1020px){
    font-size: 15px;
}
`

const TextBlock = styled.p`
    text-align: left;
    @media all and (max-width: 1020px){
    font-size: 12px;
}
`
const PhotoBlock = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`

const IFrameWrapper = styled.div`
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    height: 100%;
    aspect-ratio: 16/9;

    @media all and (max-width: 600px){
        width: 90%;
        aspect-ratio: 16/9;
        height: 100%;
    }
`