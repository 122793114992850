import { useState } from "react"
import styled from "styled-components"
import Project from "./Project"

export default function Carousel(props){
    let items = props.items
    const [currIndex, setCurrIndex] = useState(0)

    function updateIndex(newIndex) {
        if (newIndex < 0){
            newIndex=0;
        }else if (newIndex >= items.length) {
            newIndex = items.length - 1 ;
        }
        console.log(newIndex)
        setCurrIndex(newIndex);
    }


    return (
        <Wrapper>
            <Arrow onClick={() => updateIndex(currIndex-1)} url="./assets/icons/left.png"/>
            <ContentWrapper>
                <Inner index={currIndex}>
                    
                    { items.map((item) => (
                        <Project obj={item}/>
                        ))}
                </Inner>
            </ContentWrapper>
            <Arrow onClick={() => updateIndex(currIndex+1)} url="./assets/icons/right.png"/>
        </Wrapper>
    )

}

const Wrapper = styled.div`
    z-index: 1;
    padding-top: 1%;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    @media all and (max-width: 1020px){
        overflow-y: auto;
        padding: 0;
        
    }
    
`
const ContentWrapper = styled.div`
    //display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    width: 1020px;
    height: 50vh;
    /* padding: 2%; */

    @media all and (max-width: 1020px){
        overflow-y: auto;
        display: flex;
        padding: 0;
        height: 100%;
        
    }
    
`
const Inner = styled.div`
    transform: translate(${(props) => -props.index * 1020}px);
    display: flex;
    flex-direction: row;
    transition: 0.5s;
    width:100%;
    @media all and (max-width: 1250px){
        transform: translate(${(props) => -props.index * 860}px);  
    }
    @media all and (max-width: 1020px){
        flex-direction: column;
        overflow-y: auto;
    }
    
    

    
`

const Arrow = styled.img`
  content: url(${(props) => props.url});
  padding: 4%;
  z-index: 1;
  max-height: 40px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    scale: 1.2;
  }
  @media all and (max-width: 1020px){
    display: none;
    }
`