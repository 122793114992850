import Navbar from "../components/Navbar"
import styled from "styled-components"
import Title from "../components/Title"

export default function VidEdits() {

   return(
    <>
    <Navbar bgCol="#FFDFDF"></Navbar>
    <Title txt="PORTFOLIO"/> 
    <LinkWrapper>
      <Lin href="/portfolio"> Web Dev</Lin> |
      {/* <Lin href="/"> UI/UX </Lin> | */}
      <Lin href="/portfolio/videoediting"> Video Editing </Lin> |
      <Lin href="/portfolio/graphics"> Graphic Design </Lin>

    </LinkWrapper>
    <Wrapper >
    <IFrameWrapper>
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/uFnIiZ5gp64" title="YouTube video player" frameborder="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </IFrameWrapper>
    <IFrameWrapper>
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/2ijnGG2H1M4" title="YouTube video player" frameborder="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </IFrameWrapper>
    <IFrameWrapper>
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/3ze8q7rpGXU" title="YouTube video player" frameborder="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </IFrameWrapper>
    <IFrameWrapper>
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/3t5lxc-3RzE" title="YouTube video player" frameborder="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </IFrameWrapper>
    <IFrameWrapper>
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/zgosHsrBGNM" title="YouTube video player" frameborder="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </IFrameWrapper>

    </Wrapper>
    </>
   )
  

}

const Wrapper = styled.div`
   justify-content: center;
   align-items: center;
   display: flex;
   flex-wrap: wrap;
   padding-top: 2%;
`

const IFrameWrapper = styled.div`
    justify-content: center;
    align-items: center;
    width: 560px;
    height: 315px;
    @media all and (max-width: 600px){
        width: 90%;
        aspect-ratio: 16/9;
        height: 100%;
    }
`

const LinkWrapper = styled.div`
  display: flex;
  font-size: 1.1em;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
    position: relative;
 

`
const Lin = styled.a`
  text-decoration: none;
  color: #3E3D3D;
  padding: 0.5%;
  text-align: center;
  transition: 0.3s;
  &:hover{
    color: #FFB5B5;
    transform: scale(1.1);
  }
`