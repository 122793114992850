import styled from "styled-components"
import GridItem from "./GridItem";
import { useState } from "react"

export default function PhotoGrid(props){

    let items = props.items
    const [currIndex, setCurrIndex] = useState(0)

    function updateIndex(newIndex) {
        newIndex = currIndex+1;
        console.log(newIndex)
        setCurrIndex(newIndex);
    }



    return(
        <>
        <Wrapper>
            {/* <Inner index={currIndex}> */}
                { items.map((item) => (
                    <GridItem obj={item}/>
                    )) }
                    
            {/* </Inner> */}
        </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: center;



`
