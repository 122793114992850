import styled from 'styled-components';
import { Player } from '@lottiefiles/react-lottie-player';
import Links from '../components/Links';

export default function Landing() {
  return (
    <>
    <Wrapper>
      <Player style={{ position: "absolute", top:0, left: 0, padding: 0, margin: 0, zIndex: 0, width: "100vw", maxHeight: "100vh" }} src='/assets/bg.json' loop autoplay/>
      <ProfileCard>
        <Col1>
          <ProfilePhoto/>
            <IconContainer>
              <Icon imgSrc="/assets/icons/github.png" href='https://github.com/kiwigi' target="_blank" rel="noreferrer"/>
              <Icon imgSrc="/assets/icons/linkedin.png" href='https://www.linkedin.com/in/martha-ibarra/' target="_blank" rel="noreferrer"/>
            </IconContainer>
        </Col1>
        <CardText>
          <Name> Martha Ibarra </Name>
          <Bio> Hi! I'm Mar. I'm a new grad passionate about web development, video editing and design.</Bio>
          <Links>
     
          </Links>
        </CardText>
        
      </ProfileCard>
    </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  text-align: center;
  align-items: center;
  z-index: -1;
  background-color: #FFDFDF;
  overflow: hidden;
  `
  const ProfileCard = styled.div`
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 1%;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 70%;
  height: 60%;
  // background-color:white;

`
const Col1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const ProfilePhoto = styled.img`
  content: url("/assets/memojiHD.png");
  border-radius: 50%;
  max-width: 250px;
  max-height: 250px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  &:hover{
    content: url("/assets/me.png");
    transform: scale(1.1);

  }
  @media all and (max-width: 720px ){
    width: 50%;
    &:hover{
      content: url("/assets/me.png");
      transform: scale(1);
  
    }
  }
`
const IconContainer = styled.div`
  display: flex;
  width: 30%;
  max-width: 250px;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 720px ) {
    width: 20%;
    max-width: 100px;

  }
`
const Icon = styled.a`
  content: url(${(props => props.imgSrc)});
  max-width: 25px;
  padding: 50%;
  transition: 0.3s;
  &:hover{
    transform: scale(1.1);

  }
`
const CardText = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  color: black;
  max-width: 50%;
  font-size: 45px;
  @media all and (max-width: 720px ){
    font-size: 25px;
    max-width: 80%;
  }
`
const Name = styled.div`
  font-weight: 600;
  padding: 2%;
  
`
const Bio = styled.div`
  font-size: 0.7em;
  padding: 1%;
`
