import styled from "styled-components"
import Navbar from "../../components/Navbar"
import Title from "../../components/Title"
import * as FinalSketches from './duoFinalSketches.json';
import * as UIv1 from './duoUI1.json';
import * as UIv2 from './duoUI2.json';

import { Player } from '@lottiefiles/react-lottie-player';
import PhotoGrid from "../../components/PhotoGrid";

export default function DuoCaseStudy(){

    const data2 = Object.entries(FinalSketches)
    let arr = data2.pop()
    arr = arr.pop()

    const data = Object.entries(UIv1)
    let arr2 = data.pop()
    arr2 = arr2.pop()

    const data3 = Object.entries(UIv2)
    let arr3 = data3.pop()
    arr3 = arr3.pop()
   
    return(
    <div className="scrollable">
        <Wrapper>
            <Navbar bgCol="rgba(0, 0, 0, 0)"></Navbar>
            <Title txt= "DUOQ"/> 
            <Poster imgSrc="/assets/projimgs/duofinder/duoqAdwide.png"></Poster>
            <Content>
            <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  00 Background & Context  ───────
                </Heading>
    
                <TextBlock>
                Valorant is a competitive team-based tactical shooter. It relies heavily on teamwork and you usually team up with random players (or you can play with friends if you’re lucky) and communicate important information via voice chat. This includes things like enemy location, possible strategies to win the game and more. Seems pretty simple right? Unfortunately, many players abuse the voice chat feature to harass their teammates and it creates very negative and hostile environments that makes it difficult to enjoy the game.
                </TextBlock>
                <TextBlock>
                This is especially worse for people that don’t “sound like cis-males”. Female gamers and other minorities face a lot of hate, and harassment simply by speaking into voice chat and speaking into voice chat is essentially crucial to winning games. As a female gamer, this issue affected me personally and I grew tired of it. Especially because once you get into a game with random people you’re usually stuck with them for around 30 minutes (the average time it takes to finish a Valorant match). For me this meant being constantly harassed for 30 minutes or more. Of course leaving the game is always an option however you will face penalties if you do this like increased queue times and even bans. Because of this, I wanted to be able to meet players to play with so that I didn’t have to deal with random players anymore. This is where DuoQ comes in.
                </TextBlock>

                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  01 Project Description & Requirements ───────
                </Heading>
                <TextBlock>
                Essentially, DuoQ is a way for people to find other players to play with. You are matched with random people based on your settings and preferences, you then get to talk to them for about 10 minutes where you then decide if you want to share your game information with them or not. Afterwards, users can give the other person a score which contributes to their “reputation rating” which hopefully helps users decide if the person is worth their time or not!
                </TextBlock>
                <TextBlock>
                Although it started off as a solo passion project, I ended up taking a web development class with many of my good friends. I proposed this project as an idea for our final term project. We decided to go with this one and I worked on a team of 5 where we designed and developed a fully working website!
                </TextBlock>
                <TextBlock>
                    Project Requirements:
                    <li>
                        Authentication: Create account and log into account.
                    </li>
                    <li>
                        Personal profile: Edit account information and settings (e.g. display name) and filtering criteria (e.g. match age range, rank range, server location, gender, etc).

                    </li>
                    <li>
                        Player Matching Mechanism: Users are paired up with another active user, which is then the start of their conversation. Users will be paired based on their settings and reputation. A basic real-time texting feature must also exist.

                    </li>
                    <li>
                        Timed Chats: A user has 10 minutes to chat with someone, mimicking speed-dating.
                    </li>
                    <li>
                        Match History: A user can see a list of profiles of users that they have previously matched with.

                    </li>
                    <li>
                        Rating/Commedation System : Ability to rate another user that you matched with.
                    </li>
                </TextBlock>
                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  02 UI Mock-Ups Ver 0.0 ───────
                </Heading>
                <TextBlock>
                    I was bored one day and decided to quickly mock-up a UI design for this project in Adobe XD. Not much thought went into it and I jumped straight into a high fidelity design and this is what I initially came up with.
                </TextBlock>
                <PhotoBlock>
                    <Photo imgSrc="/assets/projimgs/duofinder/old1.png"/>
                    <Photo imgSrc="/assets/projimgs/duofinder/old2.png"/>
                </PhotoBlock>
                <TextBlock>
                    Once my group and I decided we were going with this idea for our group project, I decided to get more serious about the design process and we all agreed that a redesign was necessary. It resembled a dating app far too much to be what we needed it to be.
                </TextBlock>
             
                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  03 Redesign Process ───────
                </Heading>
                <TextBlock>
                    Influenced by the HCI course I was taking at the time, I proposed that we all sketched our own versions of the UI and we came together at a later time to discuss what we liked/disliked about every sketch and came up with a final sketch together afterwards. Although I don't have access to my teammate's sketches anymore, here's the sketch I was able to come up with:
                </TextBlock>
                <PhotoBlock>
                    <Photo imgSrc="/assets/projimgs/duofinder/sketches/i1.png"></Photo>
                    <Photo imgSrc="/assets/projimgs/duofinder/sketches/i2.png"></Photo>
                    <Photo imgSrc="/assets/projimgs/duofinder/sketches/i3.png"></Photo>
                </PhotoBlock>
                <TextBlock>
                    We met up and discussed the pros and cons of each design. Taking the ideas that we liked and discarding the ones that didn't work. After hours of discussion, we came up with the final sketches below. These sketches represent a screen in the userflow. Not all screens had a sketch, which became an issue when we moved to the next step.
                </TextBlock>

                <PhotoGrid items={arr}/>
               

                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  04 UI Mock-Ups Ver 1.0 ───────
                </Heading>
                
                <TextBlock>
                    We spent many hours in person and in voice call discussing and working on the UI together. This is what we eventually came up with. Although we were not 100% happy with it, due to the deadline of the project approaching we had to finish up the mock-ups and start the implementation.
                </TextBlock>

                <PhotoGrid items={arr2}/>

                <TextBlock>
                    There are a few differences from our original sketches. While we were designing the high-fidelity version of the UI, we ran into some things that we didn't like and wanted to change and some things that we didn't have a sketch for. Dealing with these changes and trying to design the visuals of the UI was a bit overwhelming at times and it made me wonder if it might be better to spend more time finalizing the low-fidelity designs before moving on.
                </TextBlock>


                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  05 Implementation ───────
                </Heading>

                <TextBlock>
                    This is the part where I show off the final product. A product demo will be up soon. Stay tuned! 
                </TextBlock>
              
                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  06 Retrospective ───────
                </Heading>

                <TextBlock>
                This was my first time working in a team where we collaborated on designing a UI. I learned a lot from the experience. At first, I found it difficult to listen to critique of my design and oftentimes I took it to heart. However, I was able to conquer that feeling and the resulting design was so much better than anything I could’ve ever come up with on my own. I learned how important working as a team really is. I feel so proud of the project that we ended up with! Being able to bounce off design ideas with other people and potential users was invaluable. However, no project is ever perfect.
                </TextBlock>

                <TextBlock>
                    Due to the time constraint, we had to rush a lot of the design process. Had we had more time, I would have loved to conduct some user research prior to creating the initial sketches. Looking at the design I can also tell that some screens and menus could be improved and that the overall quality of the design could be more modern-looking and polished. 
                </TextBlock>

                <TextBlock>
                    Thankfully, my team has decided to keep working on this project. Instead of a website, we want to make it a phone application first and go from there. I'm really excited to see where this project will go!
                </TextBlock>

                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  07 UI Mock-Ups Ver 2.0  ───────
                </Heading>
                <TextBlock>
                    Since we decided to keep working on the project, I've taken more of a UI Designer/ Graphic Designer role in the team. Currently, we are focusing on the authentication and chat functionalities so I have only made mock-ups for those screens.
                </TextBlock>
                <PhotoGrid items={arr3}/>

                <TextBlock>
                    This project really shows how far I've come as a UI/UX Designer. I feel like these final mock-ups are so much better than what I started with and I'm realy proud of that! If you'd like to help me improve, please don't hesitate to reach out. I would love some feedback on my design and on how I could improve!
                </TextBlock>

            </Content>
        </Wrapper>

    </div>

    )
}

const Wrapper = styled.div`
    justify-content: center;
    align-items: center;
    width: 99.5vw;
    z-index: -1;
    background-color: #FFDFDF;
    overflow-x: hidden;
`
const Poster = styled.img`
    content: url(${(props) => props.imgSrc});
    border-radius: 34px;
    max-width: 80vw;
    padding: 3%;
    object-fit: cover;

`
const Content = styled.div`
    background: white;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    border-radius: 34px;
    padding: 3%;
    height: 100%;
    width: 75vw;

`
const SnowFlake = styled.img`
    content: url(${(props) => props.imgSrc});
    height: 40px;
    width: 40px;
    @media all and (max-width: 1020px){
    height: 20px;
    width: 20px;
}

`
const Photo = styled.img`
    /* display: none; */
    content: url(${(props) => props.imgSrc});
    border-radius: 34px;
    max-width: 500px;
    max-height: 500px;
    object-fit: cover;
    padding: 2%;
    @media all and (max-width: 1020px){
    max-width: 80vw;
    }

`
const IntroImg = styled.img`
   content: url(${(props) => props.imgSrc});
    max-width: 100%;
    object-fit: cover;
    aspect-ratio: 16/9;
    border-radius: 34px; 
`

const Heading = styled.h1`
    font-size: 30px;
    text-align: left;
    display: flex;
    @media all and (max-width: 1020px){
    font-size: 15px;
}
`

const TextBlock = styled.p`
    text-align: left;
    @media all and (max-width: 1020px){
    font-size: 12px;
}
`
const PhotoBlock = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`

const IFrameWrapper = styled.div`
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    height: 100%;
    aspect-ratio: 16/9;

    @media all and (max-width: 600px){
        width: 90%;
        aspect-ratio: 16/9;
        height: 100%;
    }
`