import styled from "styled-components"
import Navbar from "../../components/Navbar"
import Title from "../../components/Title"

export default function NightOwlCaseStudy(){
    return(
    <div className="scrollable">
        <Wrapper>
            <Navbar bgCol="rgba(0, 0, 0, 0)"></Navbar>
            <Title txt= "NIGHTOWL CASE STUDY"/> 
            <Content>
                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp; The Product ───────
                </Heading>
                <IntroImg imgSrc="/assets/projimgs/twitchmote/g1.png"/>
                <TextBlock>
                    NightOwl is a food delivery app for a unique restaurant that operates during night time and caters to 'night-owls', people that tend to stay up late.
                </TextBlock>
                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  00 My Role & Responsibilities  ───────
                </Heading>
                <TextBlock>
                    Lead UX Designer in charge of researching, designing, wireframing, prototyping 
                </TextBlock>
                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  01 The problem & Goals ───────
                </Heading>
                <TextBlock>
                    Designing an equitable, user-friendly food delivery app for NightOwl.
                </TextBlock>
                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  02 User Research ───────
                </Heading>
                <TextBlock>
                    Conducted interviews on X number of participants. The following questions were asked:
                    <li>
                        What motivates you to order late-night food?
                    </li>
                    <li>
                        Could you describe your experience ordering late-night food?
                    </li>
                    <li>
                        How do you usually decide what menu items to order?
                    </li>
                    <li>
                        What do you like about food delivery apps like SkipTheDishes and UberEats? Why?
                    </li>
                    <li>
                        What do you dislike about food delivery apps like SkipTheDishes and UberEats? Why?
                    </li>
                </TextBlock>
                <TextBlock>

                    Main pain points:
                    <li>
                        Users identified innacurate delivery time estimates to be a main pain point for night-time food delivery.
                    </li>
                    <li>
                        Users identified a lack of transparent pricing before checkout as a main pain point for food delivery services.
                    </li>

                    Other findings:
                    <li>
                        Most users use food delivery services to save time and because they are quick and convinient.
                    </li>
                    <li>
                        Users appriciate that there is a way to get support with your order after it has been placed
                    </li>
                </TextBlock>
                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  03 User Personas ───────
                </Heading>
                <TextBlock>
                Since there was no working product at the time, most descriptions are hypothetical situations based on interviews conducted on possible Twitchmote users. Possible users were asked about their experience using a popular avatar creator app called “Bitmoji” and if they were a Twitch streamer, they were also asked about preferences and what they would like to see as a possible feature in this kind of application. Since I could not find every possible user type to interview, I hypothesized 2 of the users of the system.
                </TextBlock>
                <PhotoBlock>
                    <Photo imgSrc="/assets/projimgs/twitchmote/persona1.png"></Photo>
                    <Photo imgSrc="/assets/projimgs/twitchmote/persona2.png"></Photo>
                    <Photo imgSrc="/assets/projimgs/twitchmote/persona3.png"></Photo>

                </PhotoBlock>
                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  04 Initial Sketches ───────
                </Heading>
                <PhotoBlock>
                    <Photo imgSrc="/assets/projimgs/twitchmote/sketch1.png"></Photo>
                    <Photo imgSrc="/assets/projimgs/twitchmote/sketch2.png"></Photo>
                    <Photo imgSrc="/assets/projimgs/twitchmote/sketch3.png"></Photo>
                    <Photo imgSrc="/assets/projimgs/twitchmote/sketch4.png"></Photo>
                    <Photo imgSrc="/assets/projimgs/twitchmote/sketch5.png"></Photo>
                    <Photo imgSrc="/assets/projimgs/twitchmote/sketch6.png"></Photo>
                    <Photo imgSrc="/assets/projimgs/twitchmote/sketch7.png"></Photo>
                </PhotoBlock>
                <TextBlock>
                    After conducting walkthroughs using the personas created previously, I determined that the last design was probably the best one I came up with. I desided to go forward with that design.
                </TextBlock>
                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  06 Implementation ───────
                </Heading>
                <PhotoBlock>
                    <Photo imgSrc="/assets/projimgs/twitchmote/OLD.png"></Photo>
                    <Photo imgSrc="/assets/projimgs/twitchmote/old3.png"></Photo>
                    <Photo imgSrc="/assets/projimgs/twitchmote/old2.png"></Photo>
                </PhotoBlock>
                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  07 Design Evaluation ───────
                </Heading>
                <TextBlock>
                Severity is classified in a 1-5 scale where 1 is not very impactful and 5 is severely impactful. Problems detected classified by heuristics. Below is an example of the many heuristics that were tested and how:
                </TextBlock>
                <TextBlock>
                1 - Speak the user’s language
                <li>
                ‘Shirt’ is randomly under the face category and that doesn’t make much intuitive sense - Severity: 2
                </li>
                <li>
                ‘Avatar preview’ randomly floating – Makes the label a bit useless since it’s not directly on top of the avatar preview - Severity: 1
                </li>
                <li>
                A lot of users from America might use this site since Twitch is so popular in America. It might be better to consider using ‘color’ instead of the Canadian spelling, ‘colour’ - Severity: 1
                </li>
                <li>
                There are also many international Twitch users, and it might be a good idea to add icons to all the menu options, not just the top ones so that non-English speakers can also navigate the website - Severity: 2   
                </li>
                </TextBlock>
                
                <TextBlock>
                I conducted these tests on other heuristics like interaction feedback, design consistency, clearly marked exits and so on. Here is a summary of my findings: I was able to find a lot more issues than I was expecting – and that’s most-likely still not all of them. For some reason, prior to this evaluation, I didn’t even think about adding things like error messages in case something went wrong or adding a way to remove certain features that have already been selected from the avatar. When I put myself inside the user’s shoes, I can see how the lack of these things could lead to some major frustration! Also pressing the ‘ok’ button in the ‘start over’ window is way too easy, and I found myself being frustrated after I accidentally deleted the saved avatar twice while testing the website, I think this one is major along with the lack of undo/redo functionality. Overall, the error prevention portion I think needs to be worked on the most since most issues there scored a 3 or higher in severity rating. The undo/redo function is probably incredibly important for a tool like this as well so that should also be a priority if I ever decide to keep working on this project. There are still several kinks that need to be worked out in this prototype, some are very major. It would also help if I could do some more user testing using this prototype to find the issues that I am currently not able to see on my own. 

                </TextBlock>

                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  07 Design Rationale & Discussion ───────
                </Heading>
                <TextBlock>
                There were some things that my design did right like providing feedback every time the user completed an action most of the time. This feedback manifested in many ways. For example, changing the avatar preview whenever a feature was selected, giving an alert message whenever the avatar was saved or deleted and visually showing the user which part of the avatar creation they were in by changing the colour of the tab they were in. In that way you could say that the website gave feedback. The website was also visually consistent (except for the alerts) for the most part. Same fonts / colours were used across the website as well as the buttons for example being the same shape and colour. 
                </TextBlock>
                <TextBlock>
                I would also like to point out that the current menu is not optimal for the number of features that are currently available, but it was made thinking that there would be several features to choose from in the future. The reason I say this is mostly because of the separation of colour / shape of things. For example, eyes. For the amount of selection we currently have, it would be better to combine colour and eye shape into one category. However, because I was thinking about the possibility of having a lot of different eyes to choose from in the future, listing the eye shape and colour under the same menu would make the user’s experience painful and frustrating. That was my rationale for separating those menus.    
                </TextBlock>
                <TextBlock>
                Visually, the colour scheme was chosen to be purple since purple is Twitch’s signature colour and I went with a simple design that hopefully doesn’t overwhelm the user. Things in the menu are listed left from right for the first menu and top from bottom on the side menu in order of what I expect users will be creating the avatars in. E.g., Choosing skin tone first then eyes, mouth, hair and finally makeup. This was done in the hopes to provide the user with some familiarity. It is something that resembles most avatar / character creation tools since it is usually done in an order like this, however users can go in a different order if they choose to, which provides flexibility. 
                </TextBlock>
                <TextBlock>
                There also needs to be some work done on some of the visuals of the website. For example, the alert messages should be changed, art should be improved, and some icons need to be changed into something more legible. Also, quite honestly, although this wasn’t any of the heuristics, I think the site is majorly visually un-appealing due to my lack of CSS skills and it would need a massive visual re-vamp.  Then again, this is only a prototype. 
                </TextBlock>
                <TextBlock>
                I would again like to comment on the importance of the undo/redo function for this tool. Users are constantly selecting different features just to preview them. If the user for example has a hair style that they are happy with but would like to see what a different hairstyle might look like with their avatar, they shouldn’t have to struggle to look through 50 hairstyles to try to find the one they were originally happy with once they’re done trying out the new hairstyle. Therefore, undo is important. It is also important in case a user mis-clicks and accidently selects a hairstyle they didn’t want. The system should be able to undo so that the user doesn’t have to struggle to correct a common mistake like this. Due to the limited amount of time, I was not able to implement this function but if I were to continue working on this tool, this function would be my top priority. 
                </TextBlock>
                <TextBlock>
                Because of the lack of undo/redo I think most users would struggle to use the tool. Of course, because there is such a limited selection of features right now, this isn’t as big of a problem right now. However, for this tool to properly fulfill it’s goal as a custom emote maker for Twitch, there will need to be a lot of variety in colours and styles for all features. 
                </TextBlock>
                <TextBlock>
                With the undo/redo implementation, more selection of different styles and colours and with the fixing of the many kinks I found, I do think this tool has potential. The menu is compact and, in my opinion, easy to navigate. However, in its current state, this tool would not work well solely because of the lack of avatar customization (due to not having many styles/ colours for features) and also because it needs more work aesthetically and we really need that undo/redo functionality for this tool.
                </TextBlock>
                <Heading>
                    <SnowFlake imgSrc="/assets/favicon.ico"/>
                    &nbsp;  08 Retrospective & Redesign ───────
                </Heading>
                <TextBlock>
                    Aesthetically, this project needed a lot of work. This was one of the first UIs I designed and almost a year and a half later, I have improved a lot in the UI design department. So, I decided to get to work and redisgn the project in Figma using my improved skills and knowledge of WCAG colour contrast ratio practices (the colour contrast ratio was really bad in the original design). I also re-drew the art since I have slightly improved in that department as well. Here's what the resulting mock-up ended up looking like! In retrospect, I jumped straight into the implementation of the website without a high fidelity UI-mockup. This was my first mistake. It is very difficult to figure out a good looking design while implementing the functionality at the same time and I really struggled. Which lead to the final product looking very poor. I have since learned that a high fidelity mock-up is essential not only in making the implementation easier but also in user testing. Going forward, I would love to conduct more user testing on the newly redesigned prototype to find any issues I might not be seeing. 
                </TextBlock>
                <IntroImg imgSrc="/assets/projimgs/twitchmote/NEW.png"/>
            </Content>
        </Wrapper>

    </div>

    )
}

const Wrapper = styled.div`
    justify-content: center;
    align-items: center;
    width: 100vw;
    z-index: -1;
    background-color: #FFDFDF;
`
const Content = styled.div`
    background: white;
    margin-left: auto;
    margin-right: auto;
    border-radius: 34px;
    margin-top: 50px;
    padding: 3%;
    height: 100%;
    width: 80vw;
    justify-content: center;

`
const SnowFlake = styled.img`
    content: url(${(props) => props.imgSrc});
    height: 40px;
    width: 40px;
    @media all and (max-width: 1020px){
    height: 20px;
    width: 20px;
}

`
const Photo = styled.img`
    /* display: none; */
    content: url(${(props) => props.imgSrc});
    border-radius: 34px;
    max-width: 500px;
    object-fit: cover;
    padding: 2%;
    @media all and (max-width: 1020px){
    max-width: 80vw;
    }

`
const IntroImg = styled.img`
   content: url(${(props) => props.imgSrc});
    max-width: 100%;
    object-fit: cover;
    aspect-ratio: 16/9;
    border-radius: 34px; 
`

const Heading = styled.h1`
    font-size: 30px;
    text-align: left;
    display: flex;
    @media all and (max-width: 1020px){
    font-size: 15px;
}
`

const TextBlock = styled.p`
    text-align: left;
    @media all and (max-width: 1020px){
    font-size: 12px;
}
`
const PhotoBlock = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`