import styled from 'styled-components';

export default function Links(){
    return(
        <>
        <Wrapper>
            <Lin href="/portfolio">PORTFOLIO</Lin>
            <Lin href="/resume">RESUME</Lin>
            <Lin href="/contact">CONTACT</Lin>
        </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
  display: flex;
  font-size: 0.5em;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
  position: relative;
 

`
const Lin = styled.a`
  text-decoration: none;
  color: #3E3D3D;
  padding: 3.5%;
  text-align: center;
  transition: 0.3s;
  &:hover{
    color: #FFB5B5;
    transform: scale(1.1);
  }
  @media all and (max-width: 1020px){
    font-size: 1.1em;
  }
`