import styled from "styled-components"

export default function Title(props){

    return(
        <Wrapper>
            {props.txt}
        </Wrapper>
    )
}

const Wrapper = styled.div`
  font-weight: 500;
  font-size: 40px;
  padding: 1%;
  z-index: 1;
  position: relative;
  @media all and (max-width: 700px){
   font-size: 1.5em;
  }
`