import Navbar from "../components/Navbar"
import styled from 'styled-components';
import { Player } from '@lottiefiles/react-lottie-player';

export default function Contact() {
    return(
     <>
     <Navbar bgCol="rgba(0, 0, 0, 0)"></Navbar>
    <Wrapper>
      <Player style={{ position: "absolute", top:0, left: 0, padding: 0, margin: 0, zIndex: 0, width: "100vw", }} src='/assets/bubbleBg.json' loop autoplay/>
      <ProfileCard>
        <Col1>
          <ProfilePhoto/>
            
        </Col1>
        <CardText>
          <Name>Hey! Wanna Chat?</Name>
          <Bio>Let's chat!</Bio>
          <IconContainer>
            <a href='mailto:martha.ibarra@ucalgary.ca'>
              <Icon imgSrc="/assets/icons/email.png" href='mailto:martha.ibarra@ucalgary.ca'/>
             {'\u2003'} Email me
            </a>
            <a href='https://www.linkedin.com/in/martha-ibarra/' target="_blank" rel="noreferrer" >
              <Icon imgSrc="/assets/icons/linkedin.png" href='https://www.linkedin.com/in/martha-ibarra/' />
              {'\u2003'} Connect with me on Linkedin
            </a>
            </IconContainer>
        </CardText>
        
      </ProfileCard>
    </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  top: 0;
  position: absolute;
  width: 100vw;
  justify-content: center;
  text-align: center;
  align-items: center;
  z-index: -1;
  background-color: #FFDFDF;
  overflow: hidden;
  `
  const ProfileCard = styled.div`
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 1%;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 60%;
  @media all and (max-width: 720px){
    flex-direction: column;
  }
`
const Col1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const ProfilePhoto = styled.img`
  content: url("/assets/call.png");
  border-radius: 50%;
  max-width: 250px;
  max-height: 250px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;

  @media all and (max-width: 720px ){
    width: 50%;
  }
`
const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  margin: auto;

 
  
`
const Icon = styled.a`
  content: url(${(props => props.imgSrc)});
  max-width: 20px;
  transition: 0.3s;

  
`
const CardText = styled.div`
  display: flex;
  flex-direction: column;
  color: black;
  width: 40%;
  font-size: 45px;
  & a {
    text-align: left;
    font-size: 0.5em;
    text-decoration: none;
    color: black;
    transition: 0.3s;
    &:hover{
        transform: scale(1.1);
    
      }
  }
  @media all and (max-width: 720px ){
    font-size: 25px;
    width: 70%;
  }
`
const Name = styled.div`
  font-weight: 600;
  padding: 1.5%;
  
`
const Bio = styled.div`
  font-size: 0.7em;
  padding: 3%;
`


