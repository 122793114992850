import styled from "styled-components"

export default function GridItem(props){

    let obj = props.obj


    return(
        <>
        <Wrapper>
            <Elem>
                <Title>

                {obj.Title}
                </Title>
                <ImgStyle imgUrl={obj.ImageUrl} />
                <ImgDesc>{obj.Description}</ImgDesc>
            </Elem>
        </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
display: flex;
justify-content: center;
flex-wrap: wrap;

`
const Elem = styled.div`
    padding: 3%;
    box-sizing: border-box;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
`
const Title = styled.h3`
    @media all and (max-width: 1020px){
        font-size: 13px;
    }

`
const ImgStyle = styled.img`
    content: url(${(props) => props.imgUrl});
    object-fit: cover;
    max-width: 500px;

    @media all and (max-width: 1020px){
        max-width: 70vw;
    }

`
const ImgDesc = styled.p`
    font-size: 15px;
    max-width: 500px;
    text-align: left;
    @media all and (max-width: 1020px){
        font-size: 12px;
    }
`