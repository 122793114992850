import Navbar from "../components/Navbar"
import Title from "../components/Title"
import styled from "styled-components"
import '../App.css';

export default function Resume() {
    return(
     <div className="scrollable">
     <Navbar bgCol="#FFDFDF"></Navbar>
     <Title txt= "RESUME"/> 
     <Wrapper>
        <Col>
            <SectionTitle >
                <Asterisk>* {'\u2002'} </Asterisk>
                Experience
            </SectionTitle>
            <ColEntry>
                Content Creator | Frever {'\u2003'} {'\u2003'} {'\u2003'} {'\u2003'} {'\u2003'} {'\u2003'} {'\u2003'} {'\u2003'} {'\u2003'} {'\u2003'} {'\u00A0'} 10/22 - Present 
                <EntryDesc>
                    Created viral videos to promote a new App on social media
                </EntryDesc>
                <EntryDesc>
                    Consistently created, edited and posted video content 7 days a week         
                </EntryDesc>
                <EntryDesc>
                    Made changes to content in accordance to feedback                
                </EntryDesc>
            </ColEntry>
            <ColEntry>
                Technical Support Analyst | University of Calgary {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'} 01/20 - 07/20
                <EntryDesc>
                    Worked as a member of the MFA team to get 19,000+ faculty member accounts working with Microsoft 2FA. This included in-person, and phone client consultations where we educated clients on the security benefits of 2FA, and how to use the new 2FA system.
                </EntryDesc>
                <EntryDesc>
                    Ensured client satisfaction while troubleshooting issues that came up during 2FA set-up.
                </EntryDesc>
            </ColEntry>
            <ColEntry>
                Sales Associate | The Children's Place {'\u2003'} {'\u2003'} {'\u2003'} {'\u2003'} {'\u2003'} {'\u2003'}  06/16 - 01/20 
                <EntryDesc>
                    Helped customers through the cash register. This included processing purchases and returns while maintaining a friendly, conversational and professional attitude.
                </EntryDesc>
                <EntryDesc>
                    Maintained store re-stocked and clean to facilitate a pleasant shopping experience.
                </EntryDesc>
            </ColEntry>
        </Col>
        <Col>
        <SectionTitle >
                <Asterisk>* {'\u2002'}</Asterisk>
                Education
            </SectionTitle>
            <ColEntry>
                University of Calgary {'\u2003'} {'\u2003'} 09/15 - 12/22
                <EntryDesc>
                    Bachelor of Science in computer science
                </EntryDesc>
            </ColEntry>
            <SectionTitle >
                <Asterisk>* {'\u2002'}</Asterisk>
                Skills
            </SectionTitle>
            <ColEntry>
                <EntryDesc>
                    HTML
                </EntryDesc>
                <EntryDesc>
                    JavaScript
                </EntryDesc>
                <EntryDesc>
                    CSS
                </EntryDesc>
                <EntryDesc>
                    React
                </EntryDesc>
                <EntryDesc>
                    MySQL
                </EntryDesc>
                <EntryDesc>
                    Firebase
                </EntryDesc>
                <EntryDesc>
                    Git
                </EntryDesc>
                <EntryDesc>
                    Figma
                </EntryDesc>
                <EntryDesc>
                    Adobe Creative Suite
                </EntryDesc>

            </ColEntry>

        </Col>
     </Wrapper>
     </div>
    )
   
 
 }


 const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    justify-content: center;
    @media all and (max-width: 720px){
        justify-content: left;
    }
 `

 const Col = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 27px;
    padding: 3%;
    text-align: left;
    max-width: 45%;
    @media all and (max-width: 720px){
        padding: 1%;
        font-size: 20px;
        max-width: 100%;
        margin: 1%;
    }
 `

 const ColEntry = styled.div`
    font-size: 0.7em;
    padding-top: 2%;
    padding-bottom: 2%;
 `

 const EntryDesc = styled.li`
    font-size: 0.9em;
    padding-top: 1%;
 `
 const SectionTitle = styled.div`
    display: flex;
 `
 const Asterisk = styled.div`
    font-size: 40px;
    color: #FFB5B5;
    @media all and (max-width: 720px){
        display: none;
    }
 `