import styled from "styled-components"
import { useState } from "react"

export default function Project(props){
    
    const [isToggled, setToggled] = useState(true)
    let obj = props.obj

    function toggleImg(){
      
      if(isToggled=== true){
         setToggled(false);
      }
      else{
        setToggled(true);
    }
    return isToggled
   }


    return(
        <>
        <Wrapper>
            <IconContainer>
                <Icon  href={obj.GitLink} imgSrc="assets/icons/github.png" target="_blank" rel="noreferrer"></Icon>
                <Icon display={obj.WebUrl === "" ? "none": "hidden"} href= {obj.WebUrl} imgSrc="assets/icons/link.png" target="_blank" rel="noreferrer"></Icon>
                <Icon display={obj.DemoUrl === "" ? "none": "hidden"} href= {obj.DemoUrl} imgSrc="assets/icons/video.png" target="_blank" rel="noreferrer"></Icon>

            </IconContainer>
            {/* true =  desktop, false = mobile*/}
            <PreviewImg onClick={() => toggleImg()} 
                imgUrl = { obj.ImageUrl.length>1 ? isToggled ? obj.ImageUrl[0] : obj.ImageUrl[1] : obj.ImageUrl} 
                res={obj.Res ? "16 / 9" : "9 / 16"} >
                
            </PreviewImg>
            <TextWrapper>
                <Title> {obj.Name}</Title>
                <ToolsUsed>{obj.Tools}</ToolsUsed>
                <CaseStudyBtn display={obj.CaseStudyUrl === "" ? "none" : "hidden"} href={obj.CaseStudyUrl}>Case Study &gt;&gt; </CaseStudyBtn>
                <Text>{obj.Description}</Text>
            </TextWrapper>
        </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
    /* border-style: dotted; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 3%; */
    margin: 20px;
    max-height: 40vh;
    width: 980px;
    @media all and (max-width: 1020px){
        justify-content: center;
        height: 40vh;
        max-width: 70vw;
        flex-direction: column;
        padding: 0;
        margin-left: auto;
        margin-right: auto;


    }

`

const PreviewImg = styled.img`
    content: url(${(props) => props.imgUrl});
    border-radius: 30px;
    height: 90%;
    /* max-height: 1000px; */
    /* max-width: 600px; */
    aspect-ratio: ${(props) => props.res};
    object-fit: cover;
    transition: 0.3s;
    transform: rotate(-5deg);
    &:hover{
        transform: scale(1.05);
        cursor: pointer;
    
      }
    @media all and (min-height: 1050px){
        height: 70%;
    }
    @media all and (min-height: 1300px){
        height: 60%;
    }
    @media all and (max-width: 1500px){
        height: 100%;
    }
    @media all and (max-width: 1500px) and (min-height: 1300px){
        height: 60%;
    }
    @media all and (max-width: 1250px){
        max-width: 400px;
    }
    @media all and (max-width: 1020px){
        display: none;
        
    }
`

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column; 
    transform: translateX(-10%) translateY(8%) rotate(10deg);
    padding: 3%;
    box-sizing: border-box;
    border-top: 15px solid transparent;
    border-bottom: 35px solid transparent;
    width: 390px;
    /* max-width: 390px; */
    height: 100%;
    max-height: 400px;
    /* max-height: 350px; */
    font-size: 27px;
    overflow-y: auto;
    border-radius: 30px;
    margin-left: 2%;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
    @media all and (max-width: 1020px){
        transform: rotate(0deg);
        height: 40vh;
        width: 70vw;
        margin: 0;
        font-size: 20px;
        border: 10px solid #FFDFDF;
        box-shadow: none;
    }
`
const CaseStudyBtn = styled.a`
    display: ${(props) => props.display};
    background: #FFDDDD;
    text-decoration: none;
    text-align: left;
    color: #000000;
    border-radius: 10px;
    margin-left: auto;
    font-size: 0.5em;
    padding: 3%;
    width: 100px;
    &:hover{
        box-shadow: 0px 0px 7px 1px #FFB5B5;
        transform: scale(1.05);
        cursor: pointer;
        transition: 0.5s;
    }
    @media all and (max-width: 1020px){
        text-align: center;
        width: 80px;
        margin-left: auto;
        margin-right: auto;

    }
`

const Title = styled.div`
    text-align: right;
    padding: 1%;
    @media all and (max-width: 1020px){
        text-align: center;
    }

`
const ToolsUsed =styled.div`
    font-size: 0.5em;
    text-align: right; 
    padding: 1%;
    padding-bottom: 5%;
    @media all and (max-width: 1020px){
        text-align: center;
    }
`

const Text = styled.div`
    font-size: 0.5em;
    text-align: left;
    padding: 2%;
`

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 40px;
  justify-content: center;
  align-items: center;
  margin-right: 5%;
  transform: rotate(-5deg);
  @media all and (max-width: 1020px ) {
    width: 100%;
    flex-direction: row;
    margin: 0;
    padding: 1%;
    order: 2;
    transform: rotate(0deg);


  }
`
const Icon = styled.a`
  content: url(${(props => props.imgSrc)});
  max-width: 30px;
  padding: 50%;
  transition: 0.3s;
  display: ${(props => props.display) } ;
  &:hover{
    transform: scale(1.2);

  }
  @media all and (max-width: 1020px){
    width: 15px;
    padding: 20%;
    
}
`